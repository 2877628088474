import styled from 'styled-components';
import tw from 'twin.macro';
import { NextArrow, PrevArrow } from '../icons';

export const CarouselPrevArrow = styled(PrevArrow)`
  &::before {
    content: none;
  }
  ${tw`
    left[3%]
    md:left[10%]
  `}
`;

export const CarouselNextArrow = styled(NextArrow)`
  &::before {
    content: none;
  }
  ${tw`
    right[3%]
    md:right[10%]
  `}
`;

export const StyledImg = styled.img`
  height: 340px;
  width: 340px;
  object-fit: cover;
  border-radius: 25px;
`;
