import styled from 'styled-components';
import tw from 'twin.macro';

export const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Topics = styled.div``;

type TopicProps = {
  index: number;
  length: number;
};

const getBordersWidth = ({ index, length }) => {
  if (index === 0) return tw`border-width[2px]`;
  if (index === length - 1) return tw`border-width[0px 2px 2px 2px]`;
  return tw`border-width[0px 2px 2px 2px]`;
};

const getBordersRadius = ({ index, length }) => {
  if (index === 0) return tw`border-radius[25px 25px 0px 0px]`;
  if (index === length - 1) return tw`border-radius[0px 0px 25px 25px]`;
  return tw`border-radius[0px]`;
};

export const Topic = styled.div<TopicProps>`
  ${getBordersWidth}
  ${getBordersRadius}
`;

export const Question = styled.div<TopicProps>`
  ${getBordersRadius}
  ${tw`
    p-8
    cursor-pointer
    hover:bg-primary-600
    hover:text-primary-300
    h-full
  `}
`;

export const AnswerBox = styled.div`
  ${tw`
    pb-2
    px-8
  `}
`;

export const Answer = styled.div`
  ${tw`
    pt-4
    border-top-width[1px]
  `}
`;
