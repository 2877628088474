import React from 'react';
import { BackgroundSection } from 'src/components/layout/layout';
import 'twin.macro';
import { Title, SubTitle } from './style';
import InnerHTML from 'dangerously-set-html-content';

export const DonateSection = () => {
  return (
    <BackgroundSection className="bg-primary-600 py-12">
      <div className="grid grid-cols-1 lg:grid-cols-2 align-items[center]">
        <div className="mb-8 lg:mb-0">
          <Title>Help us continue our mission</Title>
          <SubTitle className="text-primary-300">
            100% of your donation is tax deductible and will benefit bounties on BIPBounty.org.
          </SubTitle>
        </div>
        <div className="flex lg:justify-end">
          <InnerHTML
            html={`<script id="tgb-widget-script"> !function(t,e,i,n,o,c,d,s){ t.tgbWidgetOptions={id:o,domain:n}, (d=e.createElement(i)).src=[n,"widget/script.js"].join(""), d.async=1, (s=e.getElementById(c)).parentNode.insertBefore(d,s) }(window,document,"script","https://tgbwidget.com/","1189132443","tgb-widget-script"); </script>`}
          />
        </div>
      </div>
    </BackgroundSection>
  );
};
