import React, { useState } from 'react';
import { H5, Paragraph } from 'src/components/lib/typography';
import { Topics, Title, Topic, Answer, Question, AnswerBox } from './style';
import 'twin.macro';
import { DownArrow, UpArrow } from 'src/components/lib/icons';
import { ContentSection } from 'src/components/layout/layout';
import { useGetAllFaqs } from 'src/cms';

export const FAQSection = () => {
  const topics = useGetAllFaqs();
  const [topicsState, setTopicsState] = useState<boolean[]>(topics.map(() => false));

  return (
    <div className="my-32">
      <ContentSection>
        <Title>Frequently asked questions</Title>
        <div className="mt-12">
          <Topics>
            {topics.map((topic, index) => (
              <Topic key={index} index={index} length={topics.length}>
                <Question
                  index={index}
                  length={topics.length}
                  onClick={() =>
                    setTopicsState((prev) => {
                      prev[index] = !prev[index];
                      return [...prev];
                    })
                  }
                >
                  <div className="flex justify-between">
                    <H5 className="pr-4">{topic.question}</H5>
                    <div>{topicsState[index] ? <UpArrow /> : <DownArrow />}</div>
                  </div>
                </Question>
                {topicsState[index] && (
                  <AnswerBox>
                    <Answer>
                      <Paragraph>{topic.answer.answer}</Paragraph>
                    </Answer>
                  </AnswerBox>
                )}
              </Topic>
            ))}
          </Topics>
        </div>
      </ContentSection>
    </div>
  );
};
