import React from 'react';
import { H1 } from 'src/components/lib/typography';
import { Box, Cover, ImageBox, StyledImage, StyledSection, TextBox, TextSpan } from './style';

export const HeroSection = () => {
  return (
    <StyledSection>
      <Cover>
        <Box>
          <TextBox>
            <H1>
              Tax Deductible Bug Bounties for <TextSpan>Bitcoin</TextSpan>
            </H1>
          </TextBox>
          <ImageBox>
            <StyledImage src="bitcoin.png" />
          </ImageBox>
        </Box>
      </Cover>
    </StyledSection>
  );
};
