import React, { useState } from 'react';
import Slider from 'react-slick';
import { useGetAllTeam } from 'src/cms';
import { MemberName, MemberRole } from 'src/components/home/team/style';
import 'twin.macro';
import { CarouselNextArrow, CarouselPrevArrow, StyledImg } from './style';

export const TeamCarousel = () => {
  const team = useGetAllTeam();

  const [selectedItem, setSelectedItem] = useState<number>(0);

  const settings = {
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CarouselPrevArrow />,
    nextArrow: <CarouselNextArrow />,
    afterChange: (current: number) => setSelectedItem(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {team.map((member, index) => {
          return (
            <div key={index} className="px-4">
              <div className="flex justify-center">
                <StyledImg src={member.teamPicture.url} />
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="mt-14 text-align[center]">
        <MemberName>{team[selectedItem].name}</MemberName>
        <MemberRole>{team[selectedItem].jobTitle}</MemberRole>
      </div>
    </>
  );
};
