import * as React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { FAQSection } from 'src/components/home/faq';
import { HeroSection } from 'src/components/home/hero';
import { HeroParagraph } from 'src/components/home/heroParagraph';
import { MissionSection } from 'src/components/home/mission';
import { NewBip } from 'src/components/home/newBip';
import { OpenBipBounties } from 'src/components/home/openBipBounties';
import { RewardsSection } from 'src/components/home/rewards';
import { SupportedBySection } from 'src/components/home/supportedBy';
import { TeamSection } from 'src/components/home/team';
import { Layout } from 'src/components/layout/layout';
import { DonateSection } from 'src/components/donate/section';

const IndexPage = () => {
  return (
    <Layout>
      <HeroSection />
      <SupportedBySection />
      <HeroParagraph />
      <OpenBipBounties />
      <NewBip />
      <MissionSection />
      <RewardsSection />
      <TeamSection />
      <FAQSection />
      <DonateSection />
    </Layout>
  );
};
export default IndexPage;
