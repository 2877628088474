import styled from 'styled-components';
import tw from 'twin.macro';

interface BoxProps {
  primary: boolean;
}

interface TextProps {
  primary: boolean;
  fontSize?: string;
  fontFamily?: string;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 34px;
  padding: 0 8px;
  border-radius: 6px;
  ${({ primary }) => (primary ? tw`bg-primary-600` : tw`text-white`)}
  ${({ primary }) => !primary && tw`border-2`}
`;

export const Text = styled.span<TextProps>`
  font-style: normal;
  font-weight: 700;
  line-height: ${({ primary }) => (primary ? '24px' : '32px')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Inter')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  font-weight: ${({ primary }) => (primary ? '700' : '600')};

  ${({ primary }) => (primary ? tw`text-primary-300` : tw`text-black`)}
  ${({ primary }) => !primary && tw`text-transform[uppercase]`}
`;
