import React from 'react';
import { ContentSection } from 'src/components/layout/layout';
import { H3 } from 'src/components/lib/typography';
import { Box } from './style';
import 'twin.macro';

export const HeroParagraph = () => {
  return (
    <div className="my-32">
      <ContentSection>
        <Box>
          <H3>
            Incentivize the research, development, and vetting of Bitcoin Improvement Proposals by implementing a system
            for crowdsourcing tax-deductible bug bounties, and distributing them to bounty winners.
          </H3>
        </Box>
      </ContentSection>
    </div>
  );
};
