import React from 'react';
import { useGetAllBounties } from 'src/cms';
import { ContentSection } from 'src/components/layout/layout';
import BipCard from 'src/components/lib/bipCard';
import { Tag } from 'src/components/lib/tag';
import 'twin.macro';
import { StyledTitle } from './style';

export const OpenBipBounties = () => {
  const [bounty] = useGetAllBounties();
  return (
    <div className="my-32">
      <ContentSection>
        <div className="flex align-items[center] mb-6">
          <StyledTitle>Open BIP Bounties</StyledTitle>
          <Tag className="hidden lg:flex" primary>
            More bounties coming soon!
          </Tag>
        </div>

        <BipCard lg clickable id={bounty.id} title={bounty.title} author={bounty.author} />
      </ContentSection>
    </div>
  );
};
