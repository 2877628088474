import React from 'react';
import { H3 } from 'src/components/lib/typography';
import { SubmitButton } from './style';
import { BackgroundSection } from 'src/components/layout/layout';
import 'twin.macro';
import { navigate } from 'gatsby';

export const NewBip = () => {
  return (
    <div className="my-32">
      <BackgroundSection className="bg-primary-600 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-12 align-items[center]">
          <div className="col-span-6 mb-8 lg:mb-0">
            <H3>
              Do you have a new BIP to be listed? <div className="text-primary-300">Get in touch!</div>
            </H3>
          </div>
          <div className="col-span-6 flex lg:justify-end">
            <SubmitButton onClick={() => navigate('/submit-bip')}>Submit a bip</SubmitButton>
          </div>
        </div>
      </BackgroundSection>
    </div>
  );
};
