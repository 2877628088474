import React from 'react';
import { ContentSection } from 'src/components/layout/layout';
import { Strong } from 'src/components/lib/typography';
import 'twin.macro';
import { Section, SupportedBy } from './style';

export const SupportedBySection = () => {
  return (
    <ContentSection>
      <Section>
        <SupportedBy>
          <Strong>SupportedBy:</Strong>
        </SupportedBy>
        <div style={{ margin: "0 20px" }} className="flex justify-between align-items[center] width[480px]">
          <a href="https://www.thefai.org/" target="_blank" className="pr-4">
            <img style={{ maxWidth: "210px", height: "45px", }} src="the-fai_logo.svg" alt="the fai logo" />
          </a>
        </div>
        <div className="flex justify-between align-items[center] width[480px]">
          <a href="https://www.hydrahost.com/" target="_blank" className="pl-4">
            <img src="hydra_logo.svg" alt="hydra logo" />
          </a>
        </div>
      </Section>
    </ContentSection>
  );
};
