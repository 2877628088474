import React from 'react';
import { Card, CardTag, SubmitReportButton, DonateButton, CardActions, LeftActions, RightActions } from './style';
import { H2, H5 } from 'src/components/lib/typography';
import 'twin.macro';
import { navigate } from 'gatsby';
import { BountyType } from 'src/cms/interfaces/bounty';

type BipCardProps = {
  id?: BountyType['id'];
  title: BountyType['title'];
  author: BountyType['author'];
  lg: boolean;
  clickable?: boolean;
};

export default function BipCard({ clickable = false, id, title, author, lg }: BipCardProps) {
  return (
    <Card
      lg={lg}
      clickable={clickable}
      onClick={() => {
        if (id && clickable) {
          navigate(`/bounties/${id}`);
        }
      }}
    >
      <div>
        <H5>BIP - 119</H5>
        <H2 className="line-height[normal] word-break[break-all]">{title}</H2>
        <div>{author}</div>
      </div>
      <CardActions lg={lg}>
        <LeftActions lg={lg}>
          <div className="flex flex-wrap">
            <CardTag className="h-8 mb-3 mr-3">Total Bounty Up To: 5.3 BTC</CardTag>
            <CardTag className="h-8">5.1 - .53 BTC p/ vulnerability</CardTag>
          </div>
        </LeftActions>
        <RightActions lg={lg}>
          <SubmitReportButton
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              if (id) {
                navigate(`/bounties/submit-report/${id}`);
              }
            }}
          >
            Submit Report
          </SubmitReportButton>
          <DonateButton
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              navigate('/donate');
            }}
          >
            Donate
          </DonateButton>
        </RightActions>
      </CardActions>
    </Card>
  );
}
