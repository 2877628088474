import styled from 'styled-components';

export const Section = styled.div`
  margin: 48px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 960px) {
    justify-content: center;
  }
`;

export const SupportedBy = styled.div`
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
`;
