import React from 'react';
import { ContentSection } from 'src/components/layout/layout';
import { H2, Paragraph } from 'src/components/lib/typography';
import 'twin.macro';

export const MissionSection = () => {
  return (
    <ContentSection>
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="lg:col-start-1 lg:col-end-5 mb-12 lg:mb-0">
          <div className="h-full flex flex-direction-column justify-center">
            <div className="mb-6">
              <H2>Our mission is to support the open source development of Bitcoin</H2>
            </div>
            <Paragraph>
              We do this by incentivizing the research, development, and vetting of Bitcoin Improvement Proposals by
              creating a system for crowdsourcing tax-deductible bug bounties, and distributing them to bounty winners.
            </Paragraph>
          </div>
        </div>
        <div className="lg:col-start-6 lg:col-end-13 flex justify-center lg:justify-end">
          <img src="hexagons.svg" />
        </div>
      </div>
    </ContentSection>
  );
};
