import styled from 'styled-components';
import tw from 'twin.macro';

export const Title = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 120%;

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 120%;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 150%;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
