import React from 'react';
import { Box, Text } from './style';

type TagProps = {
  primary?: boolean;
  children: React.ReactNode;
  className?: string;
  fontSize?: string;
  fontFamily?: string;
};

export const Tag = ({ primary, className, fontSize, fontFamily, children }: TagProps) => {
  return (
    <Box primary={primary} className={className}>
      <Text primary={primary} fontSize={fontSize} fontFamily={fontFamily}>
        {children}
      </Text>
    </Box>
  );
};
