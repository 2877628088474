import styled from 'styled-components';

export const StyledSection = styled.div`
  max-width: 1360px;
  width: 90%;
  margin: 28px auto;
`;

export const Cover = styled.div`
  background: #f6f6f6;
  border-radius: 25px;
  height: calc(70vh - 100px);
  min-height: 350px;
  padding: 0 8%;
  background-image: url('cover_bg.png');
  background-position: right;
  background-size: cover;

  @media (max-width: 768px) {
    height: 520px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const TextBox = styled.div`
  width: 66.66%;
  padding-right: 42px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextSpan = styled.span`
  color: #ff7c35;
`;

export const ImageBox = styled.div`
  width: 33.33%;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 331px;

  @media (max-width: 768px) {
    max-width: 180px;
  }
`;
