import styled from 'styled-components';
import tw from 'twin.macro';

export const Label = styled.div`
  ${tw`
    cursor-pointer
    hover:bg-gray-100
    py-1
    px-4
    border-radius[6px]
  `}
`;
