import { PrimaryButton } from 'src/components/lib/button';
import styled from 'styled-components';
import tw from 'twin.macro';

type ResponsiveProps = {
  lg: boolean;
};

type CardProps = {
  clickable: boolean;
} & ResponsiveProps;

export const Card = styled.div<CardProps>`
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${({ lg }) => (lg ? '30px' : '20px')};
  min-height: ${({ lg }) => (lg ? '296px' : '344px')};

  ${({ clickable }) => clickable && tw`hover:box-shadow[5px 8px 15px #FF7C35] cursor-pointer`};

  @media (max-width: 960px) {
    background-image: url('/new-bip_bg.png');
    height: 400px;
  }

  @media (max-width: 560px) {
    background-image: url('/new-bip-mobile_bg.png');
  }

  @media (min-width: 960px) {
    background-image: ${({ lg }) => (lg ? "url('/new-bip_bg.png')" : "url('/new-bip-mobile_bg.png')")};
  }
`;

export const CardTag = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  padding: 0 12px;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`;

const CardButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;

  ${tw`
    hover:bg-primary-500
  `};
`;

export const SubmitReportButton = styled(CardButton)`
  width: 168px;
  border-color: white;
  border-width: 2px;
  ${tw`
    mr-3
  `}
`;

export const DonateButton = styled(CardButton)`
  width: 114px;
  ${tw`
    background[white]
    text-black
  `}
`;

export const CardActions = styled.div<ResponsiveProps>`
  ${({ lg }) => lg && tw`lg:flex lg:justify-between lg:align-items[flex-end]`}
  ${tw`
    w-full
  `}
`;

export const LeftActions = styled.div<ResponsiveProps>`
  ${({ lg }) => lg && tw`lg:mb-0 flex lg:justify-end lg:h-8`}
  ${tw`
    mb-6
  `}
`;
export const RightActions = styled.div<ResponsiveProps>`
  ${({ lg }) => lg && tw`lg:justify-end lg:h-8`}
  ${tw`
    flex
    h-10
  `}
`;
