import React, { useState } from 'react';
import { RichTextRender, useGetAllRewards } from 'src/cms';
import { ContentSection } from 'src/components/layout/layout';
import { Tag } from 'src/components/lib/tag';
import { H2, H3, H4 } from 'src/components/lib/typography';
import 'twin.macro';
import { Label } from './style';

export const RewardsSection = () => {
  const rewardsList = useGetAllRewards();
  const [selectedReward, setSelectedReward] = useState(rewardsList[0]);

  return (
    <div className="my-32">
      <ContentSection>
        <div className="my-8">
          <H2>Types of rewards</H2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <div className="lg:col-start-1 lg:col-end-5 mb-8 lg:mb-0">
            {rewardsList.map((reward, index) =>
              reward.name === selectedReward.name ? (
                <Tag key={index} primary fontSize="24px" fontFamily="Almarai">
                  - {reward.name}
                </Tag>
              ) : (
                <Label key={index} onClick={() => setSelectedReward(reward)}>
                  <H4>{reward.name}</H4>
                </Label>
              )
            )}
          </div>
          <div className="lg:col-start-6 lg:col-end-13">
            <H3 className="mb-2 hidden lg:block">{selectedReward.name}</H3>
            <div className="flex flex-direction[row]">
              <Tag className="mb-8">{selectedReward.tag}</Tag>
            </div>
            <RichTextRender data={selectedReward.description} />
          </div>
        </div>
      </ContentSection>
    </div>
  );
};
