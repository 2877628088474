import React from 'react';
import { BackgroundSection } from 'src/components/layout/layout';
import { TeamCarousel } from 'src/components/lib/carousel';
import { H2 } from 'src/components/lib/typography';
import 'twin.macro';

export const TeamSection = () => {
  return (
    <BackgroundSection className="py-16" backgroundColor="#F6F6F6">
      <H2 className="text-align[center] mb-8">Meet the team</H2>
      <TeamCarousel />
    </BackgroundSection>
  );
};
