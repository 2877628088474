import styled from 'styled-components';
import tw from 'twin.macro';

export const Box = styled.div`
  text-align: center;
  max-width: 920px;

  ${tw`
    mx-auto
  `}
`;
