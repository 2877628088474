import styled from 'styled-components';

export const MemberName = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
`;

export const MemberRole = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
`;
