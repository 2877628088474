import { PrimaryButton } from 'src/components/lib/button';
import { H2 } from 'src/components/lib/typography';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Card = styled.div`
  background: url('new-bip_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 296px;
`;

export const CardTag = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  padding: 0 12px;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
`;

const CardButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;

  ${tw`
    hover:bg-primary-500
  `};
`;

export const SubmitReportButton = styled(CardButton)`
  width: 168px;
  border-color: white;
  border-width: 2px;
  ${tw`
    mr-3
  `}
`;

export const DonateButton = styled(CardButton)`
  width: 114px;
  ${tw`
    background[white]
    text-black
  `}
`;

export const StyledTitle = styled(H2)`
  width: 100%;
  @media (min-width: 768px) {
    width: 334px;
  }

  ${tw`
    text-align[center]
    lg:text-align[left]
    lg:mr-4
  `}
`;
